import React, { useEffect, useState } from 'react';
import AdminLayout from '../../layouts/AdminLayout';
import PocketBase from 'pocketbase';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const pb = new PocketBase('https://influencers.sazkovaarena.cz');

const Dashboard = () => {
  const [influencers, setInfluencers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({ country: '', gender: '', minFollowers: '', maxFollowers: '' });
  const navigate = useNavigate();

  useEffect(() => {
    if (!pb.authStore.isValid) {
      navigate('/account/login');
      return;
    }

    const fetchInfluencers = async () => {
      try {
        const filterStrings = [];
        if (filters.country) filterStrings.push(`country = "${filters.country}"`);
        if (filters.gender) filterStrings.push(`gender = "${filters.gender}"`);
        if (filters.minFollowers) filterStrings.push(`followers >= ${filters.minFollowers}`);
        if (filters.maxFollowers) filterStrings.push(`followers <= ${filters.maxFollowers}`);
        if (filters.profileType) filterStrings.push(`profile_type = "${filters.profileType}"`);
        if (filters.name) filterStrings.push(`name ~ "${filters.name}"`); // New filter by name
        const sortOrder = filters.sortOrder === 'asc' ? 'followers' : '-followers';


        filterStrings.push('bio != ""');

        const filter = filterStrings.join(' && ');

        const resultList = await pb.collection('influencers').getList(currentPage, recordsPerPage + 1, {
            sort: sortOrder,
            filter: filter,
        });
        setInfluencers(resultList.items);
      } catch (error) {
        console.error('Error fetching influencers:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInfluencers();
  }, [navigate, currentPage, recordsPerPage, filters]);

  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to first page when records per page change
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    window.scrollTo({ top: 0 });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    setCurrentPage(1); // Reset to first page when filters change
  };

  return (
    <AdminLayout>
      <Helmet>
        <title>Influmia | Dashboard</title>
      </Helmet>
      <div className="flex flex-col">
        <h1 className="text-3xl font-bold text-gray-800 mb-8">Zoznam Influencerov</h1>
        <div className="bg-white p-6 rounded-lg shadow-md mb-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Filter</h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div className="col-span-1">
            <label className="text-gray-600 text-sm">Meno influencera</label>
            <input
                type="text"
                name="name"
                value={filters.name || ''}
                onChange={handleFilterChange}
                className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                placeholder="Zadaj meno"
            />
            </div>
            <div className="col-span-1">
                <label className="text-gray-600 text-sm">Zoradiť podľa followerov</label>
                <select
                    name="sortOrder"
                    value={filters.sortOrder || ''}
                    onChange={handleFilterChange}
                    className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                >
                    <option value="desc">Najviac followerov</option>
                    <option value="asc">Najmenej followerov</option>
                </select>
            </div>
            <div className="col-span-1">
              <label className="text-gray-600 text-sm">Followeri od</label>
              <input
                type="number"
                name="minFollowers"
                value={filters.minFollowers}
                onChange={handleFilterChange}
                className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                placeholder="Min"
              />
            </div>
            <div className="col-span-1">
              <label className="text-gray-600 text-sm">Followeri do</label>
              <input
                type="number"
                name="maxFollowers"
                value={filters.maxFollowers}
                onChange={handleFilterChange}
                className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                placeholder="Max"
              />
            </div>
            <div className="col-span-1">
              <label className="text-gray-600 text-sm">Krajina</label>
              <select
                id="filterCountry"
                name="country"
                value={filters.country}
                onChange={handleFilterChange}
                className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              >
                <option value="">Všetky</option>
                <option value="sk">Slovensko</option>
                <option value="cz">Česko</option>
              </select>
            </div>
            <div className="col-span-1">
              <label className="text-gray-600 text-sm">Pohlavie</label>
              <select
                id="filterGender"
                name="gender"
                value={filters.gender}
                onChange={handleFilterChange}
                className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              >
                <option value="">Všetky</option>
                <option value="Muž">Muž</option>
                <option value="Žena">Žena</option>
                <option value="Iné">Iné</option>
              </select>
            </div>
            <div className="col-span-1">
              <label className="text-gray-600 text-sm">Typ profilu</label>
              <select
                id="filterProfileType"
                name="profileType"
                value={filters.profileType}
                onChange={handleFilterChange}
                className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              >
                <option value="">Všetky</option>
                <option value="Influencer/Osoba">Influencer/Osoba</option>
                <option value="Spoločnosť">Spoločnosť</option>
                <option value="Komunita">Komunita</option>
                <option value="Médium">Médium</option>
              </select>
            </div>
          </div>
        </div>
        <div className="flex items-center mb-4">
          <label className="mr-2 text-gray-600" htmlFor="recordsPerPage">
            Záznamy na stránku:
          </label>
          <select
            id="recordsPerPage"
            value={recordsPerPage}
            onChange={handleRecordsPerPageChange}
            className="p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : (
            <>
            <div className="overflow-x-auto bg-white rounded-lg shadow-md">
              {influencers.length > 0 ? (
                <table className="min-w-full bg-white">
                  <thead>
                    <tr className="w-full bg-purple-600 text-white uppercase text-sm leading-normal">
                      <th className="py-3 px-6 text-left">Poradie</th>
                      <th className="py-3 px-6 text-left">Influencer</th>
                      <th className="py-3 px-6 text-left">Typ profilu</th>
                      <th className="py-3 px-6 text-left">Pohlavie</th>
                      <th className="py-3 px-6 text-left">Krajina</th>
                      <th className="py-3 px-6 text-right">Followeri</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-700 text-sm font-light">
                  {influencers.slice(0, influencers.length - 1).map((influencer, index) => (
                      <tr key={influencer.id} className="border-b border-gray-200 hover:bg-gray-100">
                        <td className="py-3 px-6 text-left">{index + 1 + (currentPage - 1) * recordsPerPage}.</td>
                        <td className="py-3 px-6 text-left whitespace-nowrap">
                        <a 
                          href={`https://www.instagram.com/${influencer.username}/`} 
                          className="text-purple-600 hover:underline" 
                          target="_blank" 
                          rel="noopener noreferrer"
                        >
                          {influencer.username || influencer.name}
                        </a>
                        </td>
                        <td className="py-3 px-6 text-left">{influencer.profile_type}</td>
                        <td className="py-3 px-6 text-left">{influencer.gender}</td>
                        <td className="py-3 px-6 text-left">{influencer.country.toUpperCase()}</td>
                        <td className="py-3 px-6 text-right">{influencer.followers.toLocaleString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="text-center text-gray-600">Žiadne výsledky neboli nájdené.</p>
              )}
            </div>
            <div className="flex justify-between items-center mt-4">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="p-2 bg-purple-600 text-white rounded-md disabled:bg-gray-400 disabled:cursor-not-allowed"
              >
                Predošlá
              </button>
              <span className="text-gray-700">
                Stránka {currentPage}
              </span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={influencers.length <= recordsPerPage}
                className="p-2 bg-purple-600 text-white rounded-md disabled:bg-gray-400 disabled:cursor-not-allowed"
              >
                Ďalšia
              </button>
            </div>
          </>
        )}
      </div>
    </AdminLayout>
  );
};

export default Dashboard;