import React, { useState } from 'react';
import PocketBase from 'pocketbase';

const pb = new PocketBase('https://influencers.sazkovaarena.cz');

const AdminLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleLogout = async () => {
    try {
      pb.authStore.clear(); // Odstráni token a odhlási používateľa
      window.location.href = '/'; // Presmerovanie na login stránku
    } catch (error) {
      console.error('Odhlásenie zlyhalo:', error);
    }
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      {/* Sidebar */}
      <aside
        className={`${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } w-64 bg-purple-600 text-white flex flex-col fixed inset-y-0 md:translate-x-0 transform transition-transform duration-300 z-10`}
      >
        <div className="p-6 flex justify-between items-center md:block">
          <h2 className="text-2xl font-bold">Influmia</h2>
          <button
            className="md:hidden text-white"
            onClick={() => setIsSidebarOpen(false)}
          >
            ✖
          </button>
        </div>
        <nav className="mt-6 flex flex-col space-y-4 px-6">
          <a
            href="#search-influencer"
            className="flex items-center space-x-2 text-gray-200 hover:text-white hover:bg-purple-700 p-2 rounded-md"
          >
            {/* Ikona vyhľadávania */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M10 2a8 8 0 015.3 13.7l4.4 4.4a1 1 0 01-1.4 1.4l-4.4-4.4A8 8 0 1110 2zm0 2a6 6 0 100 12 6 6 0 000-12z" />
            </svg>
            <span>Vyhľadať influencera</span>
          </a>
          <a
            href="#account-settings"
            className="flex items-center space-x-2 text-gray-200 hover:text-white hover:bg-purple-700 p-2 rounded-md"
          >
            {/* Ikona nastavení */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M12 2a1 1 0 011 1v1.1a8.002 8.002 0 015.196 14.119l.686.686a1 1 0 01-1.415 1.415l-.686-.686A8.002 8.002 0 013 12.1V11a1 1 0 012 0v1.1a6.002 6.002 0 005.675 5.98V21a1 1 0 102 0v-2.12A6.002 6.002 0 0019 12.1V11a1 1 0 112 0v1.1a8.002 8.002 0 01-7.19 7.891V21a1 1 0 11-2 0v-1.1A8.002 8.002 0 0112 2z" />
            </svg>
            <span>Nastavenia účtu</span>
          </a>
          <button
            onClick={handleLogout}
            className="flex items-center space-x-2 text-gray-200 hover:text-white hover:bg-purple-700 p-2 rounded-md"
          >
            {/* Ikona odhlásenia */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M16 13v-2H7v2h9zm-1 9h-8a3 3 0 01-3-3V5a3 3 0 013-3h8a3 3 0 013 3v4h-2V5a1 1 0 00-1-1h-8a1 1 0 00-1 1v14a1 1 0 001 1h8a1 1 0 001-1v-4h2v4a3 3 0 01-3 3z" />
              <path d="M18 10l5 4-5 4v-3H9v-2h9V10z" />
            </svg>
            <span>Odhlásiť sa</span>
          </button>
        </nav>
        <div className="mt-auto p-6">
          <p className="text-sm text-gray-300">© 2024 Influmia</p>
        </div>
      </aside>

      {/* Hamburger menu pre mobilné zariadenia */}
      <button
        className="md:hidden p-4 bg-purple-600 text-white fixed top-0 left-0 z-20"
        onClick={() => setIsSidebarOpen(true)}
      >
        ☰
      </button>

      {/* Main Content */}
      <main className="flex-1 md:ml-64 p-8 bg-gray-100">{children}</main>
    </div>
  );
};

export default AdminLayout;
