import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import { Helmet } from 'react-helmet';

const Register = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    passwordConfirm: ''
  });
  const [notification, setNotification] = useState(null);

  const pb = new PocketBase('http://127.0.0.1:8090');
  const navigate = useNavigate();

  useEffect(() => {
    if (pb.authStore.isValid) {
      navigate('/admin/dashboard');
    }
  }, [pb, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setNotification(null);

    try {
      const record = await pb.collection('users').create(formData);
      await pb.collection('users').requestVerification(formData.email);
      setNotification({ message: 'Registrácia bola úspešná! Skontrolujte svoj email pre overenie.', type: 'success' });
      setFormData({ first_name: '', last_name: '', email: '', password: '', passwordConfirm: '' });
    } catch (error) {
      let errorMessage = 'Nastala chyba pri registrácii.';
      if (error.data && error.data.email) {
        errorMessage = 'Tento email je už registrovaný.';
      } else if (error.data && error.data.password) {
        errorMessage = 'Heslo musí mať aspoň 6 znakov.';
      }
      setNotification({ message: errorMessage, type: 'error' });
    }
  };

  return (
    <>
      <Helmet>
        <title>Influmia | Registrácia</title>
      </Helmet>
      <section className="min-h-screen flex items-center justify-center bg-gray-100 text-gray-800">
        <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
          <h2 className="text-3xl font-bold text-center text-purple-600 mb-6">Registrácia</h2>
          {notification && (
            <div
              className={`p-4 rounded-lg mb-4 ${
                notification.type === 'success'
                  ? 'bg-green-100 text-green-800 border border-green-200'
                  : 'bg-red-100 text-red-800 border border-red-200'
              }`}
            >
              {notification.message}
            </div>
          )}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                Meno
              </label>
              <input
                type="text"
                id="first_name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                required
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                placeholder="Vaše meno"
              />
            </div>
            <div>
              <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                Priezvisko
              </label>
              <input
                type="text"
                id="last_name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                required
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                placeholder="Vaše priezvisko"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                placeholder="Váš email"
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Heslo
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                placeholder="Vaše heslo"
              />
            </div>
            <div>
              <label htmlFor="passwordConfirm" className="block text-sm font-medium text-gray-700">
                Potvrdenie hesla
              </label>
              <input
                type="password"
                id="passwordConfirm"
                name="passwordConfirm"
                value={formData.passwordConfirm}
                onChange={handleChange}
                required
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                placeholder="Zadajte heslo znovu"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-purple-600 text-white px-4 py-2 rounded-md font-semibold hover:bg-purple-700"
            >
              Registrovať sa
            </button>
          </form>
          <p className="mt-4 text-center text-sm text-gray-600">
            Máte už účet?{' '}
            <Link to="/account/login" className="text-purple-600 hover:underline">
              Prihláste sa tu
            </Link>
          </p>
        </div>
      </section>
    </>
  );
};

export default Register;
