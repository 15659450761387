import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import { Helmet } from 'react-helmet';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [notification, setNotification] = useState(null);

  const pb = new PocketBase('https://influencers.sazkovaarena.cz');
  const navigate = useNavigate();

  useEffect(() => {
    if (pb.authStore.isValid) {
      navigate('/admin/dashboard');
    }
  }, [pb, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setNotification(null);

    try {
      await pb.collection('users').authWithPassword(formData.email, formData.password);
      navigate('/admin/dashboard');
    } catch (error) {
      setNotification({ message: 'Nesprávny email alebo heslo.', type: 'error' });
    }
  };

  return (
    <>
      <Helmet>
        <title>Influmia | Prihlásenie</title>
      </Helmet>
      <section className="min-h-screen flex items-center justify-center bg-gray-100 text-gray-800">
        <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
          <h2 className="text-3xl font-bold text-center text-purple-600 mb-6">Prihlásenie</h2>
          {notification && (
            <div
              className={`p-4 rounded-lg mb-4 ${
                notification.type === 'success'
                  ? 'bg-green-100 text-green-800 border border-green-200'
                  : 'bg-red-100 text-red-800 border border-red-200'
              }`}
            >
              {notification.message}
            </div>
          )}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                placeholder="Váš email"
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Heslo
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                placeholder="Vaše heslo"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-purple-600 text-white px-4 py-2 rounded-md font-semibold hover:bg-purple-700"
            >
              Prihlásiť sa
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default Login;
