import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Register from './components/Register';
import Login from './components/Login';
import Dashboard from './components/admin/Dashboard';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
        < Route path="/" element={<Login />} />
          <Route path="/account/login" element={<Login />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;
